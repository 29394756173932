@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');
@import '../../../src/sass/structure/variables';
@import '../../../src/sass/structure/common';
@import './pages/common';
@import './pages/typography';
@import './pages/buttons';

*, 
*:before, 
*:after {
	box-sizing: border-box;
}

body {

	width: 100vw;
	min-height: 100vh;
	margin: 0;
	font-family: 'Lato';

	> header {

		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
		height: 240px;
		padding: 0 120px;
		background: #0060A9;

		h1 {
			padding-bottom: 4px;
			margin: 0;
			font-size: 32px;
			line-height: 120%;
			color: #FFFFFF;
			font-weight: 700;
			cursor: pointer;
		}

		ul {
			position: absolute;
			top: 100%;
			right: 0;
			width: 360px;
			max-height: 320px;
			overflow-y: auto;
			overflow-x: hidden;
			filter: drop-shadow( 0px 4px 8px rgba( 0, 0, 0, 0.08 ) );
			opacity: 0;
			transform: translateX(-110vw);
			transition: opacity .4s ease-in-out, transform 1ms linear .4s;
		}

		img {
			width: 300px;
			height: auto;
			margin: 0;
		}
	
		> div {

			position: relative;

			&.opened ul {
				transition: opacity .4s ease-in-out;
				transform: translateX(0);
				opacity: 1;
			}
		
		}

	}

	> main {
		padding: 118px 120px;
		width: 100vw;
		min-height: calc( 100vh - 240px );
		overflow-x: auto;
		overflow-y: hidden;
	}

}

code {
    font-family: 'Fira Code';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 21px;
    color: $error-color;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
