section.typography {

	display: flex;
	justify-content: center;
	align-items: flex-start;

	h1, h2, h3, h4, h5, h6, p, em, caption {
		margin: 0;
	}

	> div {

		&:not(:last-of-type){
			margin-right: 80px;
		}
	
		&:nth-of-type(2) li {

			h1 {
				font-size: $font-size-xxxl;
			}
			h2 {
				font-size: $font-size-xxl;
			}
			h3 {
				font-size: $font-size-xl;
			}
			h4 {
				font-size: $font-size-md;
			}
			h5 {
				font-size: $font-size-sm;
			}
			h6 {
				font-size: $font-size-xs;
			}
			em {

				font-size: $font-size-md;
				line-height: $line-height-sm;

				&.small {
					font-size: $font-size-sm;
				}

			}
		
		}

		> em {
			display: block;
			font-style: normal;
			font-weight: 900;
			font-size: 40px;
			line-height: 100%;
		}

		li {

			strong,
			div {
				font-family: 'Fira Code';
			}	
			> strong {
				display: block;
				margin-top: 16px;
			}
			strong {
				font-size: 16px;
				line-height: 21px;
				font-weight: normal;
				align-items: center;
				text-transform: lowercase;
				color: #F50041;
			}
			div {
				margin: 16px 0 0;
			}
		
		}

		ul {

			padding: 80px;
			margin-top: 24px;

			&:nth-of-type(1){

				background: $neutral-color-high-light;
			
				li {

					&:not(:first-of-type){
						margin-top: 80px
					}

				}

			}

			&:nth-of-type(2){

				background: $brand-color-primary-pure;
				color: #ffffff;

				li:not(:first-child){
					margin-top: 32px;
				}
			
			}
		
		}
	
	}

}