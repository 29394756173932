body {

	background-color: $neutral-color-high-light;

	&.select,
	&.typography,
	&.favicon,
	&.sliders,
	&.input {
		background: $neutral-color-high-pure;
	}

}

.icons-list {

	display: flex;
	flex-wrap: wrap;
	
	li {
		margin: 0 2px 2px 0;
	}

}

section.page {
	
	h1, h2, h3, h4, h5 {
		margin: 0;
	}
	h2 {
		margin-bottom: 48px;
	}
	h3 {
		margin: 34px 0;
	}
	h4 {
		margin-bottom: 22px;
	}
	h5 {
		margin-bottom: 38px;
	}

	&.style {

		&--2 {

			h1 {
				margin: 0 0 128px;
			}
			h2, h3 {
				margin: 0;
			}
			h4 {
				margin: 24px 0;
			}

			h3 + .components-list {
				margin-top: 24px;
			}

			> div {

				&:not(:first-child){
					padding-top: 128px;
					margin-top: 75px;
					border-top: 4px solid $neutral-color-low-pure;
				}

				> div {

					display: flex;
					align-items: flex-start;

					> h2 {
						width: 350px;
						min-width: 350px;
					}
					> div {

						flex: 1;
						display: flex;

						> div {
							margin-right: 150px;
						}

					}

					ul {
						display: flex;
					}

					li:not(:last-child){
						margin-right: 24px;
					}

					&:not(:first-of-type){
						padding-top: 128px;
						margin-top: 128px;
						border-top: $neutral-color-low-pure 1px solid;
					}
				
				}

			}
			
		}

		&--3 {

			div.group > div:not(:first-of-type){
				margin-top: 50px;
			}

			.list-states {

				display: flex;
				padding-bottom: 38px;
				border-bottom: 1px solid $neutral-color-low-pure;

				> div:not(:last-of-type){
					padding-right: 50px;
					margin-right: 50px;
					border-right: 1px solid $neutral-color-low-pure;
				}
			
			}

			ul {

				display: flex;

				li {

					width: 146px;

					&:not(:last-of-type){
						margin-right: 50px;
					}

				}
			
			}

		}

		&--4 {

			h3 {
				margin: 42px 0 20px;
			}
			h4 {
				margin: 28px 0 12px;
			}

			ul {
				
				display: flex;
				flex-wrap: wrap;
				max-width: 1024px;

				li {

					margin: 0 48px 0 0;

					&:nth-child(4n){
						margin-right: 0;
					}
				
				}

			}

			div.strengh {

				display: flex;

				h4 {
					margin-top: 8px;
				}

				> div:not(:last-child){
					margin-right: 48px;
				}

				div.field-input {
					width: 332px;
				}
			
			}

		}

		&--5 {

			ul {

				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;

				li {

					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding-bottom: 21px;
					margin: 0 50px 120px;

					> div {
						flex: 1;
					}

					code {
						margin-top: 39px;
					}

					.page-state {
						width: 256px;
					}
				
				}
			
			}
		
		}

		&--6 {

			> div {

				display: flex;

				> div > div {

					display: flex;

					> div {
						margin-right: 160px;
					}

				}
			
			}

			li {

				display: flex;
				margin: 45px 0 0 10px;

				> em {
					margin-left: 31px;
				}
			
			}
		
		}

		&--7 {

			> div {
				display: flex;
			}

			ul {
				margin-right: 39px;
			}

			div.alerta {
				margin-bottom: 25px;
			}

		}

		&--8 {

			h3 {
				margin: 0 0 30px;
			}

			code {
				display: block;
				margin-top: 30px;
			}

			> div:not(:first-of-type) {
				margin-top: 128px;
			}

			.list {
				
				display: inline-flex;

				&.style {

					&--1 {

						li {

							width: 288px;
							min-height: 288px;
							flex-grow: 1;
							flex-shrink: 1;

							&:not(:last-of-type){
								margin-right: 144px;
							}

							&:nth-of-type(2){
								width: 360px;
							}

							&:nth-of-type(3){
								width: 647px;
							}

						}

					}

					&--2 {

						li {
							width: 400px !important;
							margin-right: 35px !important;
						}

					}

				}

			}

		}

		&--9 {

			h4:not(:first-child){
				margin-top: 73px;
			}
			li {

				width: 62px;

				> em {
					display: block;
					margin-top: 16px;
					font-size: 14px;
					line-height: 21px;
					color: $neutral-color-low-medium;
				}


				&:not(:last-of-type){
					margin-right: 56px;
				}
			
			}

			ul {
				display: flex;
			}

		}

		&--10 {

			h3 {

				margin: 0 0 40px;

				&:not(:first-of-type){
					margin-top: 117px;
				}
			
			}

			> div {

				display: flex;

				> div:not(:last-of-type) {
					margin-right: 80px;
				}
			
			}

		}

		&--11 {

			h3 {

				margin: 0 0 42px;

				&:not(:first-child){
					margin-top: 80px;
				}
			
			}

			ul.list-sizes {

				display: flex;

				h4 {
					margin-bottom: 0;
				}

				li {

					> span {
						height: 110px;
						display: flex;
						align-items: center;
					}

					&:not(:last-child){
						margin-right: 140px;
					}
				
				}
			
			}

		}

	}

}